import { Button } from "primereact/button";
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import testConfigurationService from "../../../../services/testConfiguration";
import { useMutation, useQuery, useQueryClient } from "react-query";
import NoDataFound from "../../../../components/common/NoDataFound";
import { InputText } from "primereact/inputtext";
import { useDebounce } from "primereact/hooks";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
const AssignConfiguration = ({ testCaseId, testSuiteId, assignedConfig, refetch }) => {
    const toast = useRef(null);
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [inputValue, debouncedValue, setInputValue] = useDebounce("", 400);
    const query = useQueryClient();
    useEffect(() => {
        setSelectedRow(assignedConfig ?? null);
    }, [assignedConfig]);
    const [pagination, setPagination] = useState({
        first: 0,
        page: 0,
        limit: 10,
        total: 0,
    });
    const columns = [
        {
            field: "name",
            header: "Name",
            filterElement: (options) => <InputText onChange={(e) => options.filterApplyCallback(e.value)} value={inputValue[options.field]?.value} />,
            filterField: "test_suite",
        },
    ];

    const onFilter = (e) => {
        console.log(e.filters);
        setInputValue(e.filters);
    };

    const { isLoading, data, isRefetching } = useQuery({
        queryKey: ["configVariables", { pagination }],
        queryFn: async () => {
            const params = {
                page: pagination.page + 1,
                limit: pagination.limit,
            };
            const response = await testConfigurationService.getTestConfigurations(params);
            const { data } = response;
            const { total } = data.meta;
            setPagination((pg) => ({ ...pg, total }));
            return data.data;
        },
        enabled: !!testCaseId,
        keepPreviousData: true,
        staleTime: 60 * 60 * 1000,
    });

    const { mutate: assignConfigHandler, isLoading: isSubmitting } = useMutation({
        mutationKey: ["assignConfiguration"],
        mutationFn: async () => {
            if (!selectedRow?.length && !assignedConfig?.length > 0) {
                throw new Error("Please select a row");
            }
            return await testConfigurationService.assignConfigurationsToTest({ test_suite_id: testCaseId, test_plan_item_id: testSuiteId, configurations_id: selectedRow.map((item) => item.id) });
        },
        onSuccess: async (data) => {
            console.log(data, " successfully");
            setSelectedRow([]);
            setShow(false);
            toast.current.show({ severity: "success", summary: "Message", detail: data?.message, life: 7000 });
            return (await refetch) ? refetch() : query.invalidateQueries({ queryKey: ["getSuitsItems"] });
        },
        onError: (err) => {
            toast.current.show({ severity: "error", summary: "Alert", detail: err?.message ?? err ?? "Somthing went wrong", life: 7000 });
            console.error(err);
        },
    });
    const isRowSelected = selectedRow?.length > 0;
    const hasAssignedConfig = assignedConfig?.length > 0;
    const isSaveDisabled = (!isRowSelected || isSubmitting) && !hasAssignedConfig;
    return (
        <>
            <Toast ref={toast} />
            <Button tooltip="Assign Configurations" tooltipOptions={{ position: "bottom" }} onClick={(e) => setShow(true)} icon="pi pi-ellipsis-v" text size="small" className="p-1 w-2" />
            <Dialog style={{ minWidth: "46rem" }} header="Assign Configuration" contentClassName="p-0 pb-3" visible={show} onHide={() => setShow(false)} closeOnEscape dismissable={false}>
                <DataTable
                    showGridlines
                    selectionMode={"checkbox"}
                    selection={selectedRow}
                    onSelectionChange={(e) => setSelectedRow(e.value)}
                    onPage={(event) => {
                        setPagination((pg) => {
                            return { ...pg, page: event.page, first: event.first };
                        });
                    }}
                    header={
                        <div>
                            <div className="flex align-items-center justify-content-between">
                                <p className="mb-0 text-xs">
                                    {" "}
                                    Test Suite ID {testCaseId} <br /> Test Plan item ID: {testSuiteId} <br />
                                </p>
                                <Button
                                    label="Save and close"
                                    icon="pi pi-check"
                                    disabled={isSaveDisabled}
                                    iconPos="right"
                                    size="small"
                                    onClick={assignConfigHandler}
                                    loading={isSubmitting}
                                    raised
                                    className={`${isRowSelected || hasAssignedConfig ? "" : "surface-300 text-color"} border-none  py-2 px-2`}
                                />
                            </div>
                        </div>
                    }
                    onFilter={onFilter}
                    filterDisplay="row"
                    value={data ?? []}
                    dataKey="id"
                    loading={isLoading || isRefetching}
                    className="datatable-responsive"
                    emptyMessage={<NoDataFound message={"No configurations are currently available."} />}
                    responsiveLayout="scroll"
                >
                    <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                    {columns.map((col, key) => (
                        <Column {...col} key={key} />
                    ))}
                </DataTable>
            </Dialog>
        </>
    );
};

export default AssignConfiguration;
