import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './style.scss';
import crypto from 'crypto';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { useSelector } from 'react-redux';
import _debounce from 'lodash/debounce';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import DatasetWidget from './Dataset/DatasetWidget';
import { MultiSelect } from 'primereact/multiselect';
import { suiteActions } from '../../../store/actions';
import { InputTextarea } from 'primereact/inputtextarea';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { getUserPreferenceSetting } from '../../../utils/common';
import React, { useEffect, useCallback, useState, useRef } from 'react';
import LoaderButton from '../../../components/common/loaderButton/LoaderButton';
import { componentService, userSettingsService, testPlanService, projectService, testSuiteService } from '../../../services';
import { sortableContainer, sortableElement, arrayMove, sortableHandle } from 'react-sortable-hoc';
import { Button } from 'primereact/button';
import CustomVariable from '../../CustomVariables/CustomVariable';
import AddTestInTicket from './AddTestInTicket';
import ToggleComponent from '../../../components/common/Toggle';
import SuitsSteps from '../SuitsSteps';
import { useModal } from '../../../utils/hooks';
import { Tooltip } from 'primereact/tooltip';
const itemTypes = {
  COMPONENT: 'component',
};
const componentType = {
  manual: 1,
  automated: 2,
};
const getPreferredColumns = () => {
  const prefCol = getUserPreferenceSetting('testSuite', 'showColumns');
  if (prefCol) {
    return prefCol;
  }
  return ['Description', 'Expected'];
};

export default function Suite({ onToggleMenuClick }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, closeModal, openModal } = useModal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useRef();
  const contentRef = useRef(null);
  const isEdit = params.id ? true : false;
  const { componentsForTest, activeIndex } = useSelector(state => state.suite);

  const shouldToggle = useRef(true);

  const [basicInfo, setBasicInfo] = useState({ title: '', comment: '', test_state_id: '1' , test_suite_type: searchParams.get('type_id') ?? '' });
  const [suiteStates, setSuiteStates] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [showUpperFields, setShowUpperFields] = useState(isEdit);

  const [selectedCols, setSelectedCols] = useState(getPreferredColumns());
  const [showStepsModal, setShowStepsModal] = useState(false);

  const [showVaribleModal, setShowVaribleModal] = useState(false);
  const [showTicketingModal, setShowTicketingModal] = useState(false);

  const columns = [
    { label: 'Description', value: 'Description' },
    { label: 'Expected', value: 'Expected' },
    { label: 'Before Step', value: 'Before Step' },
    { label: 'Keyword', value: 'Keyword' },
    { label: 'BRPG String', value: 'BRPG String' },
    { label: 'Obj String', value: 'Obj String' },
    { label: 'After Step', value: 'After Step' },
    { label: 'Xpath String', value: 'Xpath String' },
  ];
  const columnOrder = columns.map(column => column.label);
  useEffect(() => {
    return () => dispatch({ type: suiteActions.RESET_SUITE });
  }, [dispatch]);

  const rectifyComponents = components => {
    if (!components) {
      return [];
    }

    const generateRandomId = () => crypto.randomBytes(20).toString('hex');

    return components.map(({ component, datasets }) => {
      const randomIdForKey = generateRandomId();

      const rectifyDataset = dataset => {
        const datasetRandomId = generateRandomId();

        const rectifyStep = ({ step_info, display_id }) => ({
          ...step_info,
          display_id: display_id,
          randomIdForKey: generateRandomId(),
          id: step_info.id,
          value: step_info.value,
        });

        const rectifiedSteps = dataset.steps.map(rectifyStep);

        return {
          ...dataset,
          checked: false,
          randomIdForKey: datasetRandomId,
          steps: rectifiedSteps,
        };
      };

      const rectifiedDatasets = datasets.map(rectifyDataset);

      return {
        ...component,
        randomIdForKey: randomIdForKey,
        datasets: rectifiedDatasets,
      };
    });
  };
  useEffect(() => {
    if (isEdit) {
      const controller = new AbortController();
      testSuiteService
        .getTestSuite(params.id, controller)
        .then(res => {
          const { title, comment, test_state_id, components, test_suite_type } = res.data;
          const x = rectifyComponents(components);
          console.log(res.data, 'x');
          setBasicInfo({ title, comment, test_state_id, test_suite_type });

          dispatch({
            type: suiteActions.SET_COMPONENTS_FOR_TEST,
            data: x,
          });
          console.log(x);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [dispatch, params.id, isEdit]);

  useEffect(() => {
    const typeId = searchParams.get('type_id');
    const folder = searchParams.get('folder');
    if (!typeId || !folder) {
      navigate('/');
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    return () => {
      dispatch({ type: suiteActions.RESET_SUITE });
    };
  }, [dispatch]);

  useEffect(() => {
    if (shouldToggle.current) {
      onToggleMenuClick();
      shouldToggle.current = false;
    }
  }, [onToggleMenuClick]);

  useEffect(() => {
    const controller = new AbortController();
    testSuiteService
      .getTestSuiteStates(controller)
      .then(res => {
        setSuiteStates(res.data);
      })
      .catch(() => {});
    return () => controller.abort();
  }, []);

  const activeComponent = componentsForTest[activeIndex] || null;

  const HandleBasicInfoChange = useCallback(e => {
    setBasicInfo(prev => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }, []);

  const onSubmit = () => {
    if (basicInfo.title === '') {
      toast.current.show({ severity: 'error', summary: 'Invalid Detail', detail: 'Description/Story is required!' });
      return;
    }
    setSubmitting(true);
    const payload = {
      details: {
        title: basicInfo.title,
        test_state_id: basicInfo.test_state_id,
        test_suite_type: basicInfo.test_suite_type,
        comment: basicInfo.comment,
        folder_path_id: searchParams.get('folder'),
      },
      designed_components: componentsForTest.map(com => {
        return {
          component_id: com.id,
          project_id: com.project_id,
          datasets: com.datasets.map(ds => {
            return {
              scenario: ds.scenario,
              status: ds.status,
              steps: ds.steps.map(step => {
                console.log(step);
                return {
                  display_id: step.display_id,
                  id: step.id,
                  value: step.value,
                  override: step.override,
                  override_value: step.override_value,
                };
              }),
            };
          }),
        };
      }),
    };

    if (isEdit) {
      testSuiteService
        .updateTestSuite(params.id, payload)
        .then(res => {
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Test Suite has been updated' });
          const { components } = res.data;
          const x = rectifyComponents(components);

          if (basicInfo.test_suite_type !== searchParams.get('type_id')) {
            const newParams = new URLSearchParams(searchParams);
            newParams.set('type_id', basicInfo.test_suite_type);
            setSearchParams(newParams);
          }
          dispatch({
            type: suiteActions.SET_COMPONENTS_FOR_TEST,
            data: x,
          });

          // dispatch({type:suiteActions.RESET_SUITE})
        })
        .catch(err => {
          const errMsg = Object.entries(err.response.data.errors).map(err => <div>{err[1]}</div>);
          toast.current.show({ severity: 'error', summary: 'Error', detail: errMsg });
          console.log(err);
        })
        .finally(() => setSubmitting(false));
    } else {
      testSuiteService
        .createTestSuite(payload)
        .then(res => {
          console.log(res.data.id , "res.data.id")
          toast.current.show({ severity: 'success', summary: 'Success', detail: 'Test Suite has been created' });
          setBasicInfo({ title: '', comment: '', test_state_id: '' });
          // console.log(`/suite/${res.data?.id}?type_id=${searchParams.get('type_id')}&folder=${searchParams.get('folder')}` , "res.data.id")
          navigate(`/suite/${res.data?.id}?type_id=${searchParams.get('type_id')}&folder=${searchParams.get('folder')}`, { replace: true });
        })
        .catch(err => {
          const errMsg = Object.entries(err.response.data.errors).map(err => <div>{err[1]}</div>);
          toast.current.show({ severity: 'error', summary: 'Error', detail: errMsg });
          console.log(err);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const openVariableModal = () => {
    isEdit ? setShowVaribleModal(true) : toast.current.show({ severity: 'error', summary: 'Cannot Open Variables', detail: 'Please Save the test case first.' });
  };
  const openTicketingModal = () => {
    isEdit ? setShowTicketingModal(true) : toast.current.show({ severity: 'error', summary: 'Cannot Open Ticketing', detail: 'Please Save the test case first.' });
  };

  const handleToggle = useCallback(() => {
    const content = contentRef.current;

    if (content) {
      const targetHeight = showUpperFields ? content.scrollHeight + 'px' : '0';
      const startHeight = content.offsetHeight + 'px';

      setShowUpperFields(!showUpperFields);

      requestAnimationFrame(() => {
        content.style.transition = 'height 0.3s ease-in-out';
        content.style.height = startHeight;

        requestAnimationFrame(() => {
          content.style.transition = `height 0.3s ease-in-out`;
          content.style.height = targetHeight;
        });
      });
    }
  }, [showUpperFields]);

  const PanelHeader = ({ toggleIcon, className, toggle }) => {
    const handleColumnsChange = useCallback(e => {
      setSelectedCols(e.value);
      userSettingsService.updateSetting('preferences', 'testSuite', { showColumns: e.value });
    }, []);

    return (
      <div className={'px-3 py-1 flex align-items-center bg-blue-900 border-round text-white mb-1 position-sticky top-5 z-10'}>
        <button className={' mr-3 text-white ' + className} onClick={toggle}>
          <span className={toggleIcon}></span>
        </button>
        <span className={`cursor-pointer pr-2 border-right-1 flex gap-1`} style={{ fontSize: '12px' }} onClick={() => navigate('/folders')}>
          <i className='pi pi-arrow-left'></i> Back
        </span>
        <span className={`cursor-pointer ml-2 pr-2 border-right-1 flex gap-1`} style={{ fontSize: '12px' }} onClick={handleToggle}>
          <i className={`pi ${showUpperFields ? 'pi-angle-up' : 'pi-angle-down'}`}></i> {showUpperFields ? 'Show' : 'Hide'} fields
        </span>
        <span className={`cursor-pointer ml-2 pr-2 border-right-1`} style={{ fontSize: '12px' }} onClick={() => openModal()}>
          <i className='pi pi-eye'></i>
        </span>
        <span className='ml-auto'>
          <MultiSelect style={{ background: '#183462' }} placeholder='Select Columns' value={selectedCols} options={columns} maxSelectedLabels={3} onChange={handleColumnsChange} />
          <Button icon='pi pi-plus' className='p-button-sm p-button-text text-white mr-1' onClick={openVariableModal} style={{ padding: '11px', border: '1px solid rgb(206, 212, 218)' }} label='Variables' />
          <Button icon='pi pi-ticket' className='p-button-sm p-button-text text-white mr-1' onClick={openTicketingModal} style={{ padding: '11px', border: '1px solid rgb(206, 212, 218)' }} label='Add in Ticket' />
        </span>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast} />
      <ToggleComponent defaultCollapsed={false} header={PanelHeader}>
        <LocalCustomVariables show={showVaribleModal} setShow={setShowVaribleModal} testCaseId={params.id} />

        <TicketingModal show={showTicketingModal} setShow={setShowTicketingModal} />

        <SuitsSteps isOpen={isOpen} closeModal={closeModal} id={params.id} />

        <div ref={contentRef} className={`collapse-container ${showUpperFields ? 'collapsed' : ''}`}>
          <div className='p-fluid formgrid grid'>
            <div className='field col-12 md:col-6'>
              <div className='field col-12'>
                <div className='p-fluid formgrid grid'>
                  <div className='field col-6'>
                    <label htmlFor='state'>State</label>
                    <select className={'p-inputtext p-component'} name={'test_state_id'} onChange={HandleBasicInfoChange}>
                      <option value={''}>Select State</option>
                      {suiteStates.map(ss => {
                        return (
                          <option key={ss.test_state_id} selected={ss.id === parseInt(basicInfo.test_state_id)} value={ss.id}>
                            {ss.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className='field col-6'>
                    <label htmlFor='state'>Type</label>
                    <select className={'p-inputtext p-component'} name={'test_suite_type'} onChange={HandleBasicInfoChange}>
                      <option value={searchParams.get('type_id') ?? ''}>Select Test Type</option>
                      {[
                        { id: 1, name: 'Manual' },
                        { id: 2, name: 'Automated' },
                      ].map(ss => {
                        return (
                          <option key={ss.name} selected={ss.id === parseInt(basicInfo.test_suite_type)} value={ss.id}>
                            {ss.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className='field'>
                <label htmlFor='title'>Description/Story</label>
                <InputTextarea id='title' name='title' type='text' onChange={HandleBasicInfoChange} value={basicInfo.title} />
              </div>
            </div>

            <div className='field col-12 md:col-6'>
              <label htmlFor='name'>Pre-Requisite</label>
              <InputTextarea id='comment' name='comment' rows={'7'} type='text' onChange={HandleBasicInfoChange} value={basicInfo.comment} />
              {/*<InputText id="comment" name='comment' type="text" onChange={HandleBasicInfoChange} value={basicInfo.comment}/>*/}
            </div>
          </div>
        </div>

        <div className={`flex`}>
          <DndProvider backend={HTML5Backend}>
            <ComponentDragList />
            <ComponentDropList
              // componentsForTest={componentsForTest}
              // setComponentsForTests={setComponentsForTests}
              activeIndex={activeIndex}
            />
          </DndProvider>
          <div style={{ maxHeight: '600px', overflowY: 'auto', border: '1px solid #ced4da', borderRadius: '6px', width: '70%' }} className={'ml-2 flex-1'}>
            <table className='component-dnd-table' style={{ width: '100%' }}>
              <thead>
                <tr>
                  {columnOrder.map(
                    col =>
                      // Render only selected columns
                      selectedCols.includes(col) && <th key={col}>{col}</th>,
                  )}
                </tr>
              </thead>
              <tbody>
                {activeComponent?.steps.map(
                  (step, i) => (
                    console.log(step.override_value, 'override_value'),
                    (
                      <tr key={step.id}>
                        {columnOrder.map(
                          col =>
                            selectedCols.includes(col) && (
                              <td key={col}>
                                {col === 'Description' && i + 1 + '- ' + step.description}
                                {col === 'Expected' && (step.expected_output || 'Empty')}
                                {col === 'Before Step' && (step.before_step[0] || 'Empty')}
                                {col === 'Keyword' && step?.keyword?.name}
                                {col === 'BRPG String' && (step.brpg_obj || 'Empty')}
                                {col === 'Obj String' && step.object_string}
                                {col === 'After Step' && (step.after_step[0] || 'Empty')}
                                {col === 'Xpath String' && (step.xpath || 'Empty')}
                              </td>
                            ),
                        )}
                      </tr>
                    )
                  ),
                )}
              </tbody>
            </table>
          </div>
        </div>
      </ToggleComponent>
      <div>
        {activeComponent !== null && <DatasetWidget formSubmit={onSubmit} isSubmitting={submitting} />}
        <LoaderButton onClick={onSubmit} keepText type='submit' icon={!submitting && 'pi pi-check'} className='p-button w-auto mt-4 p-button-sm ml-auto' loading={submitting} spinnerStyle={{ borderTop: '3px solid #6366f1' }}>
          {isEdit ? 'Update' : 'Save'}
        </LoaderButton>
      </div>
    </div>
  );
}

const ComponentDragList = () => {
  const [projectOptions, setProjectOptions] = useState([]);
  const [componentFilter, setComponentFilter] = useState({
    project_id: null,
    q: null,
  });

  const [componentList, setComponentList] = useState([]);

  const getProjects = useCallback(() => {
    const controller = new AbortController();
    projectService
      .getProjects(controller)
      .then(({ data }) => {
        setProjectOptions(data);
      })
      .catch(err => {
        console.log(err);
      });
    return () => controller.abort();
  }, []);

  const getComponents = useCallback(() => {
    const controller = new AbortController();
    componentService
      .getComponents(controller, componentFilter)
      .then(({ data }) => {
        setComponentList(data);
      })
      .catch(err => {
        console.log(err);
      });

    return () => {
      controller.abort();
    };
  }, [componentFilter]);

  useEffect(() => {
    getComponents();
  }, [getComponents]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const onComponentFilterChange = e => {
    if (e.target.name === 'project_id') {
      setComponentFilter(prev => ({ ...prev, project_id: e.target.value.id }));
      return;
    }
    setComponentFilter(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <div className='p-listbox p-component drag-list-container '>
        <div className='p-listbox-header'>
          <div className='p-listbox-filter-container'>
            <Dropdown className='mb-1 w-13rem' name='project_id' id='project_id' options={projectOptions} onChange={e => onComponentFilterChange(e)} value={projectOptions.filter(p => p.id === componentFilter.project_id)[0]} optionLabel='project_name' placeholder='Projects' filter />
            <div>
              <input
                type='text'
                onChange={_debounce(e => {
                  onComponentFilterChange(e);
                }, 1000)}
                name='q'
                className='p-inputtext p-component p-listbox-filter'
                placeholder='Feature'
              />
              <span style={{ marginTop: '0.9rem' }} className='p-listbox-filter-icon pi pi-search'>
                {' '}
              </span>
            </div>
          </div>
        </div>
        <div className='p-listbox-list-wrapper '>
          <ul className='p-listbox-list add-border-in-list'>
            {componentList.map(component => {
              return <ComponentDragItem component={component} key={component.id} />;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

const ComponentDragItem = ({ component }) => {
  // const [searchParams , setSearchParams]=useSearchParams()
  // const isOfSameType=parseInt(searchParams , setSearchParams.get('type_id'))===component.type_id

  const [{ isDragging }, drag] = useDrag(() => ({
    type: itemTypes.COMPONENT,
    item: component,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // console.log(item)
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <li className={`p-listbox-item flex`} ref={drag}>
      <i className={`${component.type_id === componentType.automated ? 'pi pi-cog' : 'pi pi-ticket'} mr-1`}></i>
      {component?.feature}{' '}
      <Link className='ml-auto' target='_blank' to={`/components/${component.id}/edit`}>
        <i className='pi pi-eye'></i>
      </Link>
      {/* <i className={'ml-1 mr-1 pi pi-arrow-right'}></i> */}
      {/* {component.name} */}
    </li>
  );
};

const ComponentDropList = ({ activeIndex, activeComponent, setActiveComponent }) => {
  const { componentsForTest } = useSelector(state => state.suite);

  const dispatch = useDispatch();

  const deleteItem = useCallback(
    i => {
      const prev = [...componentsForTest];
      prev.splice(i, 1);
      dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: prev });
    },
    [componentsForTest, dispatch],
  );
  // console.log(componentsForTests)
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: itemTypes.COMPONENT,
    drop: item => {
      const droppedComponent = {
        ...item,
        steps: item.steps,
        randomIdForKey: crypto.randomBytes(20).toString('hex'),
        datasets: [
          {
            randomIdForKey: crypto.randomBytes(20).toString('hex'),
            checked: false,
            scenario: '',
            status: false,
            steps: item.steps.map(step => {
              return {
                ...step,
                display_id: step.display_id,
                id: step.id,
                value: 'skip',
                randomIdForKey: crypto.randomBytes(20).toString('hex'),
              };
            }),
          },
        ],
      };
      dispatch({ type: suiteActions.ADD_COMPONENTS_FOR_TEST, data: droppedComponent });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  console.log(canDrop, isOver);

  const setActive = (component, index) => {
    dispatch({ type: suiteActions.SET_ACTIVE_INDEX, data: index });
  };
  const DragHandle = sortableHandle(() => <Button icon='pi pi-align-justify' className='mr-2 p-0 outline-none shadow-none w-auto' text severity='secondary'></Button>);

  const SortableItem = useCallback(
    sortableElement(({ value }) => {
      const { component, i } = value;
      return (
        <li className={`p-listbox-item flex p-2  ${i === activeIndex ? 'active-item' : ''}`} key={component.id + '-' + i}>
          <Tooltip target={`.listTooltip_${i}`} position={'right'} color='volcano'>
            <span className='mb-1'>{component?.name}</span>
          </Tooltip>
          <DragHandle />
          <Button onClick={() => setActive(component, i)} className={`listTooltip_${i} p-0 flex-1 flex outline-none shadow-none`} type='button' text severity='secondary'>
            <i className={`${component.type_id === componentType.automated ? 'pi pi-ticket' : 'pi pi-cog'} mr-1`}></i>
           <span className='white-space-nowrap overflow-hidden text-overflow-ellipsis flex-1 text-left'> {component.feature}</span>
          </Button>
          <Button icon='pi pi-trash' className='w-auto text-orange-500 ml-auto outline-none shadow-none p-0' type='button' text severity='secondary' onClick={() => deleteItem(i)}></Button>
        </li>
      );
    }),
    [activeIndex, deleteItem],
  );

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className='p-listbox-list'>{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({ type: suiteActions.SET_COMPONENTS_FOR_TEST, data: arrayMove(componentsForTest, oldIndex, newIndex) });
  };
  return (
    <div>
      <div className='ml-2 p-listbox p-component drop-list-container h-full' ref={drop}>
        <div className='p-listbox-header'>
          <div className='p-listbox-filter-container'>Test Design Flow</div>
        </div>
        <div className='p-listbox-list-wrapper drop-list add-border-in-list'>
          <SortableContainer onSortEnd={onSortEnd} useDragHandle>
            {componentsForTest.map((component, i) => (
              <SortableItem key={component.id + '-' + i} index={i} value={{ component, i }} />
            ))}
          </SortableContainer>
        </div>
      </div>
    </div>
  );
};

const LocalCustomVariables = ({ show, setShow, testCaseId }) => {
  return (
    <Dialog visible={show} maximizable style={{ maxWidth: '1000px', width: '100%', backgroundColor: 'rgb(247, 247, 254)' }} header={'Variables'} modal className='p-fluid' onHide={() => setShow(false)} footer={<></>}>
      <CustomVariable testCaseId={testCaseId} isLocal={true} />
    </Dialog>
  );
};

const TicketingModal = ({ show, setShow }) => {
  return (
    <Dialog visible={show} maximizable style={{ maxWidth: '1000px', width: '100%', backgroundColor: 'rgb(247, 247, 254)' }} header={'Ticketing'} modal className='p-fluid' onHide={() => setShow(false)} footer={<></>}>
      <AddTestInTicket />
    </Dialog>
  );
};
